<template>
  <div
    class="index-container"
    v-loading="loading"
    :element-loading-text="isShowDone ? '正在查询订单支付信息...' : ''"
  >
    <div class="user">
      <el-avatar :size="60" :src="info.avatar"></el-avatar>
      <div class="user-info">
        <div class="user-name">{{ info.nickname }}</div>
        <div class="user-vip" :class="currentVip.biz_type">{{ userVip }}</div>
      </div>
    </div>

    <div class="vip" v-show="!loading">
      <div class="vip-choose">
        <div
          v-for="(item, index) in vip"
          :key="index"
          class="vip-choose-item"
          :class="[
            currentVip.biz_type == item.biz_type ? 'active' : '',
            item.biz_type,
          ]"
          @click="chooseVip(item)"
        >
          {{ item.vip_name }}
        </div>
      </div>
      <div class="vip-list">
        <ul>
          <li
            v-for="(item, ind) in currentVip.biz_list"
            :key="ind"
            :class="[
              currentVip.biz_type,
              currentVipChild == item ? 'active' : '',
            ]"
            @click="changeVip(item)"
          >
            <div class="vip-list-item">
              <div class="price">
                <span>{{ item.price }}</span
                >元
              </div>
              <div class="line"></div>
              <div class="info">
                <div class="name">{{ item.name }}</div>
                <div class="remarks">{{ item.remarks }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="vip-des">
        <p>&#8226; {{ vip_des }}</p>
        <p>&#8226; 虚拟物品一经下单，不提供退款</p>
        <p v-if="currentVip.biz_type == 'goldvip'">
          &#8226; 想要永久获得课程观看权益，请直接在单个课程下购买
        </p>
        <p>
          &#8226; 遇到任何问题，请联系行云客服微信：<span class="red"
            >zhixingwrite</span
          >
        </p>
      </div>

      <div class="btn" :class="currentVip.biz_type" @click="go">
        {{ userVip.indexOf("未开通") > -1 ? "立即开通" : "立即续费" }}
      </div>

      <div class="divider"></div>
      <div class="intro">
        <div class="title">{{ currentVip.vip_name }}权益</div>
        <div class="info" v-html="vip_introduction"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Index",
  components: {},
  data() {
    return {
      loading: true,

      info: {},

      currentVip: {},
      currentVipChild: {},

      des: {
        redvip_des: "本会员仅为范文资料会员，不涉及课程。",
        redvip_introduction:
          "1.查看和下载行云App和行云PC端范文、瀑布资料</br>" +
          "2.下载原创图书</br>" +
          "3.下载原创软件</br>" +
          "4.购买终身会员将赠送《高效信息整理术》课程</br>",

        goldvip_des: "本会员仅提供自学录播视频课程，不涉及直播课程",
        goldvip_introduction:
          "1.在会员有效期内，可以学习观看App上除【后期必学】之外的所有视频课程。</br>" +
          "2.购买1年金云会员，送1年红云会员。</br>" +
          "购买后推荐学习路径：</br>" +
          "推荐直接看【顶级课程】——【王牌训练预热课程】的六个专题，然后再去看最新更新的红桃king和方块king班训练课程！</br>" +
          "其他专题性课程可以作为补充学习，这样侧重学习效果更好！",

        bluevip_des:
          "本会员仅涉及知行写作软件服务，不涉及课程内容。会员中大量功能只能在行云PC端使用。",
        bluevip_introduction:
          "1.在服务期限内，提供不限字数的人工智能错别字纠错功能。</br>" +
          "2.开通对应时长红云会员。</br>" +
          "3.使用【北冥】功能。</br>" +
          "4.使用文章框架分解功能。</br>" +
          "5.使用智能写作辅助功能。</br>",

        greenvip_des: "开通会员解锁【训练】模块功能。",
        greenvip_introduction: 
          "1.包含红云对应全部权限。</br>" +
          "2.包含蓝云对应全部权限。</br>" +
          "3.获得【训练】模块所有训练课程学习权限。</br>",
      },

      vip: [],
      vip_des: "",
      vip_introduction: "",
      userVip: "",

      isShowDone: false,
    };
  },

  created() {
    // this.getVipInfo();
    this.isShowDone = false;
    window.addEventListener("tokenInfo", () => {
      this.getVipInfo();
    });

    /** 测试 start */
    // setTimeout(() => {
    //   this.isShowDone = true;
    //   this.getVipInfo();
    // }, 8000);
    /** 测试 end */
  },

  mounted() {},

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (from.name === "vip_pay") {
        const flag = vm.$utils.getLs("isShowDone");
        if (flag === true) {
          vm.isShowDone = true;
          vm.loading = true;
          vm.getVipInfo();
        }
      }
    });
  },

  methods: {
    getVipInfo() {
      const url = "api/v1/vipInfo";
      this.$http.getRequest(url).then(
        (res) => {
          if (this.isShowDone) {
            setTimeout(() => {
              this.loading = false;
              const type = this.currentVip.biz_type;
              const oldStr = this.info[type + "ExpireDate"];
              const newStr = res[type + "ExpireDate"];
              if (oldStr !== newStr) {
                this.$message({
                  message: "会员状态已更新",
                  type: "success",
                });
              } else {
                const orderId = this.$utils.getLs("order_id");
                const h = this.$createElement;
                this.$msgbox({
                  title: "提示",
                  message: h("p", null, [
                    h("span", null, "支付异常："),
                    h("br"),
                    h(
                      "span",
                      null,
                      "请确认是否已支付，如果是，请联系行云客服微信："
                    ),
                    h("i", { style: "color: #ff0000" }, "zhixingwrite"),
                    h("br"),
                    h("span", null, "订单号："),
                    h("i", { style: "color: #ff0000" }, orderId),
                  ]),
                  center: true,
                  showClose: false,
                  closeOnClickModal: false,
                  confirmButtonText: "知道了",
                  beforeClose: (action, instance, done) => {
                    done();
                  },
                })
                  .then(() => {})
                  .catch(() => {});
              }
              this.info = res;
              this.getInfo();
            }, 500);
          } else {
            this.loading = false;
            this.info = res;
            this.getInfo();
          }

          /** 测试 start */
          // if (this.isShowDone) {
          //   this.info.redvip = false;
          //   this.info.goldvip = false;
          // }
          // console.log(this.info);
          /** 测试 end */
        },
        () => {}
      );
    },
    getInfo() {
      const url = "api/v1/vipPrice2";
      this.$http.getRequest(url).then(
        (res) => {
          this.vip = res;

          if (!this.isShowDone) {
            this.currentVip = this.vip[0];
            this.currentVipChild = this.vip[0].biz_list[0];
          }

          this.changeDes();
          this.isShowDone = false;
        },
        () => {}
      );
    },
    chooseVip(item) {
      this.currentVip = item;
      this.currentVipChild = item.biz_list[0];
      this.changeDes();
    },

    changeVip(item) {
      this.currentVipChild = item;
    },

    changeDes() {
      this.userVip = this.currentVip.vip_name + ": ";
      this.vip_des = this.des[this.currentVip.biz_type + "_des"];
      this.vip_introduction =
        this.des[this.currentVip.biz_type + "_introduction"];

      if (this.currentVip.biz_type === "redvip") {
        if (this.info.redvipExpireDate) {
          this.userVip += this.info.redvipExpireDate;
        } else {
          this.userVip += "未开通";
        }
      } else if (this.currentVip.biz_type === "goldvip") {
        if (this.info.goldvipExpireDate) {
          this.userVip += this.info.goldvipExpireDate;
        } else {
          this.userVip += "未开通";
        }
      } else if (this.currentVip.biz_type === "bluevip") {
        if (this.info.bluevipExpireDate) {
          this.userVip += this.info.bluevipExpireDate;
        } else {
          this.userVip += "未开通";
        }
      } else if (this.currentVip.biz_type === "greenvip") {
        if (this.info.greenvipExpireDate) {
          this.userVip += this.info.greenvipExpireDate;
        } else {
          this.userVip += "未开通";
        }
      }
    },

    go() {
      let params = {
        type: this.currentVip.biz_type,
        name: this.currentVip.vip_name,
        childName: this.currentVipChild.name,
        price: this.currentVipChild.price,
        childId: this.currentVipChild.biz_id,
      };

      this.$router.push({ name: "vip_pay", params: params });
    },
  },
};
</script>

<style lang="scss" scoped>
$red: #f01f31;
$gold: #a49170;
$blue: #7fbdf6;
$green: #009966;

$redLine: #d2d2d2;
$goldLine: #e4ded7;
$blueLine: #a9cef3;
$greenLine: #d2d2d2;

$redBg: #ffe9e9;
$goldBg: #fef5e6;
$blueBg: #e8f1fa;
$greenBg: #ccffcc;

.index-container {
  height: 100%;
  .user {
    display: flex;
    margin: 20px 10px;
    align-items: center;
    .user-info {
      margin-left: 10px;
      text-align: left;
      .user-name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .user-vip {
        font-weight: 700;
      }
      .user-vip.redvip {
        color: $red;
      }
      .user-vip.goldvip {
        color: $gold;
      }
      .user-vip.bluevip {
        color: $blue;
      }
      .user-vip.greenvip {
        color: $green;
      }
    }
  }

  .vip {
    margin: 0 10px;
    .vip-choose {
      width: 100%;
      height: 40px;
      background: #e9e9e9;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      .vip-choose-item {
        flex: 1;
        margin: 4px;
        height: 32px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      .vip-choose-item.active {
        background: #fff;
        font-weight: 700;
      }
      .vip-choose-item.active.redvip {
        color: $red;
      }
      .vip-choose-item.active.goldvip {
        color: $gold;
      }
      .vip-choose-item.active.bluevip {
        color: $blue;
      }
      .vip-choose-item.active.greenvip {
        color: $green;
      }
    }

    .vip-list {
      margin-top: 20px;
      ul {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        li {
          width: 25%;
          height: auto;
          flex-shrink: 0;
          border-radius: 8px;
          margin: 0 5px;
          padding: 10px 5px;
          .price {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            span {
              font-size: 22px;
              font-weight: 700;
            }
          }

          .line {
            width: 100%;
            height: 1px;
            margin: 10px 0;
          }

          .info {
            .name {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 5px;
            }
            .remarks {
              font-size: 12px;
            }
          }
        }

        li.redvip {
          border: 1px solid $redLine;
          color: $red;

          .line {
            background: $redLine;
          }
        }
        li.goldvip {
          border: 1px solid $goldLine;
          color: $gold;

          .line {
            background: $goldLine;
          }
        }
        li.bluevip {
          border: 1px solid $blueLine;
          color: $blue;

          .line {
            background: $blueLine;
          }
        }
        li.greenvip {
          border: 1px solid $greenLine;
          color: $green;

          .line {
            background: $greenLine;
          }
        }
      }

      li.redvip.active {
        border: 1px solid $red;
        background: $redBg;

        .line {
          background: $red;
        }
      }
      li.goldvip.active {
        border: 1px solid $gold;
        background: $goldBg;

        .line {
          background: $gold;
        }
      }
      li.bluevip.active {
        border: 1px solid $blue;
        background: $blueBg;

        .line {
          background: $blue;
        }
      }
      li.greenvip.active {
        border: 1px solid $green;
        background: $greenBg;

        .line {
          background: $green;
        }
      }
    }

    .vip-des {
      color: #6d6d6d;
      text-align: left;
      margin: 20px 10px;
      p {
        line-height: 1.5;
      }
    }

    .btn {
      height: 40px;
      margin: 10px;
      color: #fff;
      font-size: 16px;
      line-height: 40px;
      border-radius: 40px;
    }

    .btn.redvip {
      background: $red;
    }
    .btn.goldvip {
      background: $gold;
    }
    .btn.bluevip {
      background: $blue;
    }
    .btn.greenvip {
      background: $green;
    }

    .divider {
      margin: 20px -10px;
      height: 10px;
      background: #f0f0f0;
    }
    .intro {
      margin: 20px 10px;
      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .info {
        height: auto;
        color: #999999;
        margin-top: 20px;
        text-align: left;
        line-height: 2;
      }
    }
  }
}
</style>
